import React from 'react'
import TextCard from '~/components/molecules/TextCard'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'
import ReferralForm from './ReferralForm'

export default function HeroReferral({ key, index, ...props }) {

  const section = props.heroReferral

  return (
    <section id={section.fieldGroupName + index} className="py-40 pb-44 md:pb-48 bg-sand relative">
      <div className="container">
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-12 md:col-span-5 mb-10 md:mb-12 grid md:grid-cols-5 h-full items-center">
            <TextCard className="text-center col-span-5 " {...section.textCard} />
          </div>
          <FadeInWhenVisible className="col-span-12 md:col-span-6 md:col-start-7 -mb-32">
            <ReferralForm/>
          </FadeInWhenVisible>
        </div>
      </div>
    </section>
  )
}