import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import InputField from '~/components/atoms/InputField';
import CheckboxField from '~/components/atoms/CheckboxField';
import Button from '~/components/atoms/Button';
import useFormSubmit from '~/hooks/useFormSubmit';

const VisuallyHiddenField = styled.div`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  pointer-events: none;
`;

const validationSchema = Yup.object({
    name: Yup.string(),
    referrer_name: Yup.string().required('Required'),
    referrer_email: Yup.string().email('Invalid email address').required('Required'),
    referrer_phone: Yup.string().required('Required'),
    property_owner_name: Yup.string().required('Required'),
    property_owner_email: Yup.string().email('Invalid email address').required('Required'),
    property_owner_phone: Yup.string().required('Required'),
    privacy_policy: Yup.boolean().oneOf([true], 'This field must be checked'),
});

export default function ReferralForm() {

    const [submitting, setSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { post } = useFormSubmit({
        portalId: '7026924',
        formId: '4ae5b622-df1d-44b5-8539-f82034e2df36',
        setIsSubmitted,
    });

    return (
        <div className={`px-8 py-12 md:py-16 md:px-20 bg-white relative border border-red-500 bg-white border border-[#EFEEEA]`}>
            <Formik
                initialValues={{
                    name: '',
                    referrer_name: '',
                    referrer_email: '',
                    referrer_phone: '',
                    property_owner_name: '',
                    property_owner_email: '',
                    property_owner_phone: '',
                    privacy_policy: false,
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, formikProps) => {

                    setSubmitting(true);

                    //Spam validation
                    if (values.name) {
                        setSubmitting(false);
                        formikProps.resetForm();
                    }
                    else {

                        delete values['name'];
                        delete values['privacy_policy'];

                        post(values).then(() => {
                            setSubmitting(false);
                            setIsSubmitted(true);
                            formikProps.resetForm();
                        });

                    }
                }}
            >
                {() => (
                    <Form id="referral-form" className="grid grid-cols-12 gap-y-8">
                        {/** Spam validation */}
                        <VisuallyHiddenField>
                            <InputField
                                divclassname="col-span-12 "
                                name={'name'}
                                type="text"
                                label={'Name'}
                            />
                        </VisuallyHiddenField>
                        {[
                            { name: 'referrer_name', label: 'Your name:' },
                            { name: 'referrer_email', label: 'Your email:' },
                            { name: 'referrer_phone', label: 'Your contact number:' },
                            { name: 'property_owner_name', label: "Your friend/family's name:" },
                            { name: 'property_owner_email', label: "Your friend/family's email:" },
                            { name: 'property_owner_phone', label: "Your friend/family's contact number:" },
                        ].map((field, index) => (
                            <InputField
                                divclassname="col-span-12 "
                                name={field.name}
                                type="text"
                                label={field.label}
                                key={index}
                            />
                        ))}
                        <CheckboxField
                            divclassname="col-span-12"
                            name="privacy_policy"
                            label={'By submitting this form, you accept our <a href="/privacy-policy">Privacy Policy</a>'}
                        />
                        <div className="col-span-12">
                            <div className="w-full">
                                <Button
                                    title={'Refer'}
                                    className={`w-full`}
                                    type="submit"
                                    submitting={submitting}
                                />
                            </div>
                            {isSubmitted && (
                                <p className='w-full my-6 text-center'>{"Your referral request was correctly sent!"}</p>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )

}